import { first, last } from 'lodash';
import { TablesNames } from '~~/types/Enums';
import { type GroupRow, type TableState } from '~~/types/TableTypes';

const getDefaults = (): TableState => ({
  nestedExpandedRows: [],
  items: [],
  headless: false,
  expandedGroups: [],
  checkedIds: null,
  groupsAdditionalInfos: {},
  focusedItem: null,
  groups: [],
  tableConfig: [],
  rowNavigationEnabled: false,
});

type TableStateRef = Ref<TableState> & {
  reset: () => void;
  toggleGroup: (groupKey: string) => void;
  isGroupExpanded: (groupKey?: string) => boolean;
  resetExpandedGroups: () => void;
  collapseGroup: (groupKey: string) => void;
  expandGroup: (groupKey: string) => void;
  toggleGroups: () => void;
  expandGroupsLevels: (level: number[]) => void;
  expandAllGroups: () => void;
  setRowNavigationEnabled: (value: boolean) => void;
  toggleCheckAll: () => void;
  toggleCheckItem: (item: WithId) => void;
  addGroup: (group: GroupRow) => void;
  sortGroups: (groups?: GroupRow[]) => GroupRow[];
  deeperGroupLevel: ComputedRef<number>;
  sortedExpandedGroups: ComputedRef<GroupRow[]>;
};

export const useDatasTablesState = (tableName?: TablesNames | string) => {
  if (!tableName) tableName = TablesNames.DEFAULT;
  let state = useState<TableState>(tableName) as TableStateRef;
  if (!state.value) {
    state = useState<TableState>(tableName, getDefaults) as TableStateRef;
  }
  state.reset = () => {
    state.value = getDefaults();
  };

  state.addGroup = (group: GroupRow) => {
    state.value.groups?.push(group);
  };

  state.sortGroups = (groups?: GroupRow[]) => {
    const entries = groups || state.value.groups || [];

    entries.sort((a, b) => {
      const aIndex = state.value.orderedGroupKeys?.indexOf(a.key) || 0;
      const bIndex = state.value.orderedGroupKeys?.indexOf(b.key) || 0;

      return aIndex - bIndex;
    });

    return entries;
  };

  state.collapseGroup = (groupKey: string) => {
    const loweredGroupKey = groupKey.toLowerCase();
    if (state.isGroupExpanded(loweredGroupKey)) {
      state.value.expandedGroups = state.value.expandedGroups.filter(
        (g) => g.key !== loweredGroupKey,
      );
    }
  };

  state.deeperGroupLevel = computed(() => {
    return Math.max(
      ...state.value.expandedGroups
        .filter((g) => g.collapsable === undefined || g.collapsable === true)
        .map((g) => g.level || 0),
    );
  });

  state.sortedExpandedGroups = computed(() => {
    return state.sortGroups(state.value.expandedGroups);
  });

  state.expandGroup = (groupKey: string) => {
    const loweredGroupKey = groupKey.toLowerCase();
    if (state.isGroupExpanded(loweredGroupKey)) return;
    const group = state.value.groups?.find((g) => g.key === loweredGroupKey);
    const isExpanded = state.isGroupExpanded(loweredGroupKey);
    if (group && !isExpanded) {
      const levelToClose = state.value.autocloseLevel || state.deeperGroupLevel.value;
      if (levelToClose > 0 && group.level === levelToClose && state.value.limitOpenedGroup) {
        const sortedExpandedGroups = state.sortedExpandedGroups.value;

        const groupsOpened = sortedExpandedGroups.filter((g) => g.level === levelToClose) || [];

        if (groupsOpened.length >= state.value.limitOpenedGroup) {
          const currentGroupIndex =
            state.value.orderedGroupKeys?.findIndex((k) => k === group.key) || 0;
          const lastGroupOpenedIndex =
            state.value.orderedGroupKeys?.findIndex((k) => k === last(groupsOpened)?.key) || 0;

          const currentGroupIsAfterLastOpened = currentGroupIndex > lastGroupOpenedIndex;

          const groupToCloseIndex = currentGroupIsAfterLastOpened
            ? sortedExpandedGroups.findIndex((g) => g.key === first(groupsOpened)?.key)
            : sortedExpandedGroups.findIndex((g) => g.key === last(groupsOpened)?.key);

          if (groupToCloseIndex >= 0) {
            state.value.expandedGroups.splice(groupToCloseIndex, 1);
          }
        }
      }

      state.value.expandedGroups.push(group);
    }
  };

  state.toggleGroup = (groupKey: string) => {
    const loweredGroupKey = groupKey.toLowerCase();
    if (state.isGroupExpanded(loweredGroupKey)) {
      state.collapseGroup(loweredGroupKey);
    } else {
      state.expandGroup(loweredGroupKey);
    }
  };

  state.isGroupExpanded = (groupKey?: string) => {
    if (!groupKey) return false;
    const loweredGroupKey = groupKey.toLowerCase();
    return !!state.value.expandedGroups?.find((g) => g.key === loweredGroupKey);
  };

  state.resetExpandedGroups = () => {
    state.value.expandedGroups = [];
  };

  state.toggleGroups = () => {
    state.value.expandedGroups = state.value.expandedGroups?.length ? [] : state.value.groups || [];
  };

  state.expandAllGroups = () => {
    state.value.expandedGroups = state.value.groups || [];
  };

  state.expandGroupsLevels = (levels: number[]) => {
    state.value.expandedGroups =
      state.value.groups?.filter((g) => {
        return !!g.level && levels.includes(g.level);
      }) || [];
  };

  state.setRowNavigationEnabled = (value: boolean) => {
    state.value.rowNavigationEnabled = value;
  };

  state.toggleCheckAll = () => {
    if (state.value.checkedIds?.length) {
      state.value.checkedIds = [];
    } else {
      state.value.checkedIds = state.value.items
        .filter((i) => !state.value.checkableCondition || state.value.checkableCondition(i))
        .map((i) => i.id);
    }
  };

  state.toggleCheckItem = (item: WithId) => {
    if (!state.value.checkedIds) return;

    if (state.value.checkableCondition && !state.value.checkableCondition(item)) return;

    if (state.value.checkedIds.includes(item.id as number)) {
      state.value.checkedIds = state.value.checkedIds.filter((id) => id !== item.id);
    } else {
      state.value.checkedIds.push(item.id as number);
    }
  };

  return state;
};
